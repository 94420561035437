import CollapseIcon from 'assets/images/collapseIcon.svg';
import classNames from 'classnames';
import Loading from 'core/common/Loading';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useFormationCargas } from 'hook/FormationCargas';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import {
  DataRouteringCargas,
  DataStepsRoutering,
  clearDataRouteSelected,
  clearDataStepSelected,
  clearDetailRouteringCarga,
  setDataRouteringCargas,
  setDataStepsSelected,
  updateAllRouteringStepsCompacted,
  updateRouteringStepsSelected,
} from 'store/modules/routering/actions';
import { colors } from 'util/colors';
import Card from './components/Card';
import CardValueKnowledge from './components/CardValueKnowledge';
import Header from './components/Header';
import SearchAddress from './components/SearchAddress';

interface Props {
  data: DataStepsRoutering[];
  dataOptmi: DataRouteringCargas;
  onMap: (latitude: number, longitude: number) => void;
  onGrouped: (
    dataOptmi: DataRouteringCargas,
    dataSteps: DataStepsRoutering[]
  ) => void;
  onClose: () => void;
}

const KnowledgeRouting: React.FC<Props> = ({
  data,
  dataOptmi,
  onMap,
  onGrouped,
  onClose,
}: Props) => {
  const [cargoData, setCargoData] = useState<DataStepsRoutering[]>(
    data
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [stepsAddress, setStepsAddress] = useState<any>(null);
  const [knowledgeAddress, setKnowledgeAddress] = useState<any>([]);
  const [isAddressChanged, setIsAddressChanged] = useState<boolean>(false);
  const [updateStep, setUpdateStep] = useState<boolean>(false);
  const [isAllowedDrag, setIsAllowedDrag] = useState(false);
  const [reordenateSteps, setReordenateSteps] = useState<DataStepsRoutering[]>(
    data
  );

  const [checkedAllSteps, setCheckedAllSteps] = useState(false);
  const [groupedSteps, setGroupedSteps] = useState<any[]>([]);

  const dataDetailRoutering: DataRouteringCargas = useSelector(
    (state: any) => state.routering.data.detail
  );

  const { dataGroupedCargas, onRemove, onChangeAddress } = useFormationCargas();

  const dispatch = useDispatch();

  console.log(data);

  const onReloadSteps = useCallback(async (arrOptmis: any) => {
    try {
      setLoading(true);

      const mappingKnowledge = arrOptmis.steps.map(
        (itemStep: DataStepsRoutering, indexStep: number) => {
          return {
            id: itemStep.conhecimentoPre.id,
            priority: 0,
          };
        }
      );

      let getCargas = sessionStorage.getItem('cargas');

      let getCargaDateScheduling;

      if (getCargas) {
        getCargaDateScheduling = JSON.parse(getCargas);
      }

      const dateScheduling = getCargaDateScheduling.find(
        (item: any) => item.id === arrOptmis.id
      ).dataAgendamento;

      let getDateScheduling = null;

      let getHoursScheduling = null;

      if (dateScheduling && dateScheduling !== null) {
        getDateScheduling = format(new Date(dateScheduling), 'yyyy-MM-dd', {
          locale: pt,
        });

        getHoursScheduling = format(new Date(dateScheduling), 'HH:mm:ss', {
          locale: pt,
        });
      }

      const dataSubmit = {
        conhecimentos: mappingKnowledge,
        rota: arrOptmis.id,
        veiculos: [
          {
            id: arrOptmis.vehicle.id,
            roadtrip: arrOptmis.roadtrip,
            outputForecast:
              getDateScheduling !== null && getHoursScheduling !== null
                ? `${getDateScheduling}T${getHoursScheduling}`
                : null,
          },
        ],
        filial: arrOptmis.subsidiary.id,
        alteraAtendimentosRota: false,
        idStep: null,
      };

      const response = await api.post(
        '/delivery/conhecimentosPre/alteraOrdemRota',
        dataSubmit
      );

      setUpdateStep(!updateStep);

      sessionStorage.setItem(
        'idSimulacao',
        JSON.stringify(response.data.id_route_simulation)
      );

      onReloadRoute();

      // setLoading(false);
    } catch (err) {}
  }, []);

  function getColors(cargaId: number) {
    const getColorsByCarga = sessionStorage.getItem('@carga/colors');

    let colorsData = [];

    if (getColorsByCarga) {
      colorsData = JSON.parse(getColorsByCarga);
    }

    const findColors = colorsData.find((color: any) => color.id === cargaId);

    return findColors;
  }

  function loadColors(
    cargaItem: DataRouteringCargas,
    data: any,
    index: number
  ) {
    const getColorsByCarga = sessionStorage.getItem('@carga/colors');

    let colorsData = [];

    if (getColorsByCarga) {
      colorsData = JSON.parse(getColorsByCarga);
    }
    const random = Math.floor(Math.random() * colors.length);

    const dataColors = {
      id: cargaItem.id,
      color: index > colors.length ? colors[random] : colors[index],
    };

    let colorsList = colorsData;

    colorsList.push(dataColors);

    if (data.length > colorsData) {
      sessionStorage.setItem('@carga/colors', JSON.stringify(colorsList));
    } else {
      sessionStorage.setItem('@carga/colors', JSON.stringify(colorsList));
    }

    return dataColors;
  }

  const onReloadRoute = useCallback(
    async (cargoType?: string) => {
      try {
        setLoading(true);

        const data = {
          filters: [
            {
              field: 'simulation.id',
              value: sessionStorage.getItem('idSimulacao'),
              operation: 'EQUAL',
            },
            {
              field: 'inactivated',
              operation: 'EQUAL',
              value: false,
            },
          ],
          page: 0,
          size: 1000,
          orders: [],
        };
        const response = await api.post('/route/optmis/pageable', data);

        if (response.data.content.length === 0) {
          if (window.location.pathname.includes('formationCargas')) {
            onClose();
          } else {
            history.push('/uploadCSV');
            window.location.reload();
          }

          setLoading(false);
        }

        const orderSteps = response.data.content.sort(
          (a: any, b: any) => a.order - b.order
        );

        const mappingCargaRoutering = orderSteps.map(
          (cargaItem: DataRouteringCargas, indexCargas: number) => {
            return {
              ...cargaItem,
              fillColor: loadColors(cargaItem, orderSteps, indexCargas).color,
              active: false,
              steps: cargaItem.steps.map(stepItem => {
                return {
                  ...stepItem,
                  isCompacted: false,
                  active: false,
                };
              }),
            };
          }
        );

        const mappingCarga = mappingCargaRoutering.map(
          (cargaItem: DataRouteringCargas, indexCarga: number) => {
            return {
              dataAgendamento: cargaItem.dataAgendamento,
              id: cargaItem.id,
            };
          }
        );

        sessionStorage.setItem('cargas', JSON.stringify(mappingCarga));

        dispatch(setDataRouteringCargas(mappingCargaRoutering));
        dispatch(clearDetailRouteringCarga());
        dispatch(clearDataRouteSelected());

        if (cargoType === 'updateAddress') {
          onReloadSteps(response.data.content[0]);
        }
      } catch (err) {}
    },
    [updateStep]
  );

  const setRouteReorderRemoveKnowledge = useCallback(
    async (
      optmisData: any,
      dataIndex: number,
      idRoute: number,
      idStep: number,
      optmiIdOrder: number
    ) => {
      try {
        setLoading(true);
        let vehiclesRemoveRoute = [];

        const reorderSteps = optmisData.steps
          .slice()
          .sort(
            (a: DataStepsRoutering, b: DataStepsRoutering) => a.order - b.order
          );
        const removeSteps = reorderSteps
          .map((item: any, i: number) => (item.id === idStep ? i : -1))
          .filter((index: number) => index !== -1);

        const filterSteps = reorderSteps.filter(
          (item: any, index: number) => index !== removeSteps[0]
        );

        let mappingKnowledge: any;

        if (reorderSteps.length === 1) {
          mappingKnowledge = [];
        } else {
          mappingKnowledge = filterSteps.map(
            (itemKnowledge: any, indexKnowledge: number) => {
              return {
                id: itemKnowledge.conhecimentoPre.id,
                priority: 0,
              };
            }
          );
        }

        let getCargas = sessionStorage.getItem('cargas');

        let getCargaDateScheduling;

        if (getCargas) {
          getCargaDateScheduling = JSON.parse(getCargas);
        }

        const dateScheduling = getCargaDateScheduling.find(
          (item: any) => item.id === optmisData.id
        ).dataAgendamento;

        let getDateScheduling = null;

        let getHoursScheduling = null;

        if (dateScheduling && dateScheduling !== null) {
          getDateScheduling = format(new Date(dateScheduling), 'yyyy-MM-dd', {
            locale: pt,
          });

          getHoursScheduling = format(new Date(dateScheduling), 'HH:mm:ss', {
            locale: pt,
          });
        }
        const dataSubmit = {
          conhecimentos: mappingKnowledge,
          rota: optmiIdOrder,
          veiculos: [
            {
              id: optmisData.vehicle.id,
              roadtrip: optmisData.roadtrip,
              outputForecast:
                getDateScheduling !== null && getHoursScheduling !== null
                  ? `${getDateScheduling}T${getHoursScheduling}`
                  : null,
            },
          ],
          filial: optmisData.subsidiary.id,
          alteraAtendimentosRota: false,
          idStep: null,
        };

        const response = await api.post(
          '/delivery/conhecimentosPre/alteraOrdemRota',
          dataSubmit
        );

        if (response.data.id_route_simulation !== null) {
          sessionStorage.setItem(
            'idSimulacao',
            JSON.stringify(response.data.id_route_simulation)
          );
        }
        setLoading(false);
        onReloadRoute();
      } catch (err) {}
    },
    []
  );

  const setRouteReorder = useCallback(
    async (
      optmisData: any,
      stepsData: DataStepsRoutering[],
      optmiIdOrder: number
    ) => {
      try {
        setLoading(true);
        const orderLoads = stepsData
          .slice()
          .sort(
            (a: DataStepsRoutering, b: DataStepsRoutering) => a.order - b.order
          );
        const mappingKnowledge = orderLoads.map(
          (itemKnowledge: any, indexKnowledge: any) => {
            return {
              id: itemKnowledge.conhecimentoPre.id,
              priority: itemKnowledge.order,
            };
          }
        );
        console.log('entrou reorder2', mappingKnowledge);

        let getCargas = sessionStorage.getItem('cargas');

        let getCargaDateScheduling;

        if (getCargas) {
          getCargaDateScheduling = JSON.parse(getCargas);
        }

        const dateScheduling = getCargaDateScheduling.find(
          (item: any) => item.id === optmisData.id
        ).dataAgendamento;

        let getDateScheduling = null;

        let getHoursScheduling = null;

        if (dateScheduling && dateScheduling !== null) {
          getDateScheduling = format(new Date(dateScheduling), 'yyyy-MM-dd', {
            locale: pt,
          });

          getHoursScheduling = format(new Date(dateScheduling), 'HH:mm:ss', {
            locale: pt,
          });
        }
        const dataSubmit = {
          conhecimentos: mappingKnowledge,
          rota: optmiIdOrder,
          veiculos: [
            {
              id: optmisData.vehicle.id,
              roadtrip: optmisData.roadtrip,
              outputForecast:
                getDateScheduling !== null && getHoursScheduling !== null
                  ? `${getDateScheduling}T${getHoursScheduling}`
                  : null,
            },
          ],
          filial: optmisData.subsidiary.id,
          alteraAtendimentosRota: false,
          idStep: null,
        };

        const response = await api.post(
          '/delivery/conhecimentosPre/alteraOrdemRota',
          dataSubmit
        );

        if (response.data.id_route_simulation !== null) {
          sessionStorage.setItem(
            'idSimulacao',
            JSON.stringify(response.data.id_route_simulation)
          );
        }

        onReloadRoute();
      } catch (err) {}
    },
    []
  );

  const updateOrderSteps = useCallback(async reorderSteps => {
    try {
      setLoading(true);

      setIsAllowedDrag(false);

      await api.patch('/route/steps/atualizaOrdem', reorderSteps);
    } catch {}
  }, []);

  const onUpdateAddress = useCallback(
    async (dataArr: any, data: any, valueAddress: any) => {
      try {
        const dataAddressKnowledge = data;

        const findKnowledgeById = dataArr
          .map((itemKnowledge: DataStepsRoutering, i: number) =>
            itemKnowledge.conhecimentoPre.id === data.id ? i : -1
          )
          .filter((item: any) => item !== -1);

        let dataKnowledgeAddress;

        if (dataArr[findKnowledgeById[0]].conhecimentoPre.tipo === 'Entrega') {
          dataKnowledgeAddress = {
            ...dataArr[findKnowledgeById[0]].conhecimentoPre,
            enderecoLogradouro: valueAddress.logradouro,
            enderecoBairro: valueAddress.bairro,
            enderecoCidade: valueAddress.cidade,
            enderecoUf: valueAddress.estado,
            enderecoCep: valueAddress.cep,
            enderecoNumero: valueAddress.numero,
            enderecoLatitude: valueAddress.latitude,
            enderecoLongitude: valueAddress.longitude,
          };
        } else {
          dataKnowledgeAddress = {
            ...dataArr[findKnowledgeById[0]].conhecimentoPre,
            enderecoLogradouroColeta: valueAddress.logradouro,
            enderecoBairroColeta: valueAddress.bairro,
            enderecoCidadeColeta: valueAddress.cidade,
            enderecoUfColeta: valueAddress.estado,
            enderecoCepColeta: valueAddress.cep,
            enderecoNumeroColeta: valueAddress.numero,
            enderecoLatitudeColeta: valueAddress.latitude,
            enderecoLongitudeColeta: valueAddress.longitude,
          };
        }

        let initialIndex = -1;
        let rowIndex = -1;

        initialIndex = dataGroupedCargas.findIndex(item => {
          // Procura o índice no array de 'rows'
          rowIndex = item.rows.findIndex(
            row => row.id === dataArr[findKnowledgeById[0]].conhecimentoPre.id
          );
          return rowIndex !== -1; // Se encontrou, retorna true para findIndex
        });

        onChangeAddress(initialIndex, rowIndex, dataKnowledgeAddress);

        const response = await api.patch(
          `/delivery/conhecimentosPre/${
            dataArr[findKnowledgeById[0]].conhecimentoPre.id
          }`,
          dataKnowledgeAddress
        );

        onReloadRoute('updateAddress');

        return dataKnowledgeAddress;
      } catch (err) {}
    },
    []
  );

  async function handleOnDragEnd(
    result: any,
    dataGroupedSteps: DataStepsRoutering[],
    dataSteps: DataStepsRoutering[]
  ) {
    if (!result.destination) return;

    let items: DataStepsRoutering[] = Array.from(dataGroupedSteps);

    if (items.length > 0) {
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const reorderedGroupedItems = items.map((item, indexReordenate) => {
        return {
          ...item,
          order: indexReordenate + 1,
        };
      });

      setGroupedSteps(reorderedGroupedItems);

      let reordernedItems: any[] = [];

      const reordernedUngroupedItems = items.map(
        (item: DataStepsRoutering, indexReordenate: number) => {
          reordernedItems.push(item);

          if (item.rows && item.rows.length > 0) {
            item.rows.map((row: DataStepsRoutering, index: number) => {
              reordernedItems.push(row);
            });
          }
        }
      );

      const reorderedItems = reordernedItems.map((item, indexReordenate) => {
        return {
          ...item,
          order: indexReordenate + 1,
        };
      });

      setCargoData(reorderedItems);
    }
    return;
  }

  useEffect(() => {
    let sortKnowledge = data
      .slice()
      .sort((a: any, b: any) => a.order - b.order);
    setCargoData(sortKnowledge);
  }, [data]);

  return (
    <div
      className={classNames('knowledgeFilter containerKnowledgeRoutering', {
        'is-open': true,
      })}
    >
      <Loading loading={loading} />

      <Header
        data={cargoData}
        isDrag={isAllowedDrag}
        isChecked={checkedAllSteps}
        onGrouped={(data: any[]) => {
          setGroupedSteps(data);
          onGrouped(dataOptmi, data);
        }}
        onConfirm={() => {
          const dataCargo = isAllowedDrag ? groupedSteps : cargoData;

          updateOrderSteps(dataCargo);

          setTimeout(() => {
            setRouteReorder(dataOptmi, dataCargo, dataOptmi.id);
          }, 2000);

          setTimeout(() => {
            setLoading(false);
          }, 5000);

          onGrouped(dataOptmi, cargoData);
        }}
        onCancel={() => {
          setIsAllowedDrag(!isAllowedDrag);
          onGrouped(dataOptmi, cargoData);
        }}
        onChangeOrder={() => setIsAllowedDrag(true)}
        onChecked={() => {
          setLoading(true);
          dispatch(clearDataStepSelected());
          dataDetailRoutering.steps.map(
            (stepSelected: DataStepsRoutering, indexStepSelected: number) => {
              let checked = false;
              if (checkedAllSteps === true) {
                dispatch(clearDataStepSelected());
              } else {
                dispatch(setDataStepsSelected(stepSelected));
              }

              dispatch(
                updateRouteringStepsSelected(
                  indexStepSelected,
                  !checkedAllSteps
                )
              );
            }
          );

          setCheckedAllSteps(!checkedAllSteps);
          setLoading(false);
        }}
      />

      <div
        className="tw-max-w-auto tw-flex tw-items-center tw-cursor-pointer tw-mt-4"
        onClick={() => {
          if (isAllowedDrag) {
            const dataCargo = Array.from(cargoData);

            const getDataStepsCompacted = dataCargo.map(
              (item: DataStepsRoutering) => {
                return {
                  ...item,
                  isCompacted: !item.isCompacted,
                };
              }
            );

            dispatch(updateAllRouteringStepsCompacted(getDataStepsCompacted));

            const cargoDataCompacted = Array.from(groupedSteps);

            const getDataStepsDataCompacted = cargoDataCompacted.map(
              (item: DataStepsRoutering) => {
                return {
                  ...item,
                  isCompacted: !item.isCompacted,
                };
              }
            );

            setGroupedSteps(getDataStepsDataCompacted);
          } else {
            const dataCargo = Array.from(cargoData);

            const getDataStepsCompacted = dataCargo.map(
              (item: DataStepsRoutering) => {
                return {
                  ...item,
                  isCompacted: !item.isCompacted,
                };
              }
            );

            dispatch(updateAllRouteringStepsCompacted(getDataStepsCompacted));
          }
        }}
      >
        <img src={CollapseIcon} className="tw-w-4 tw-h-4" />
        <span className="tw-text-xs tw-font-bold tw-ml-2 tw-text-[#003049]">
          Expandir/Recolher
        </span>
      </div>

      {isAddressChanged && (
        <SearchAddress
          onClear={() => setIsAddressChanged(!isAddressChanged)}
          data={stepsAddress}
          columns={stepsAddress}
          onEditColumns={(
            data: any,
            valueColumn: any,
            dataValueAddress: any,
            isMultipleRows: any
          ) => {
            onUpdateAddress(
              knowledgeAddress,
              data,
              dataValueAddress
            );
          }}
          accuracy={undefined}
          onInitial={undefined}
          dataColumns={undefined}
        />
      )}
      <CardValueKnowledge data={data} />

      <Card
        data={isAllowedDrag ? groupedSteps : cargoData}
        dataOptmis={dataOptmi}
        setData={(dataReorder: any) => {
          setReordenateSteps(dataReorder);
          setTimeout(() => {
            setRouteReorder(dataOptmi, dataReorder, dataOptmi.id);
          }, 2000);
        }}
        onUpdateData={(dataFilter, indexFilter, routeId, stepId) => {
          const removeSteps = dataFilter.steps
            .map((item: any, i: number) => (item.id === stepId ? i : -1))
            .filter((index: number) => index !== -1);
          const filterSteps = dataFilter.steps.filter(
            (item: any, index: number) => index !== removeSteps[0]
          );

          setRouteReorderRemoveKnowledge(
            dataFilter,
            indexFilter,
            routeId,
            Number(stepId),
            dataOptmi.id
          );

          const getRemoveSteps = dataFilter.steps.filter(
            (item: any) => item.id === stepId
          );

          let initialIndex = -1;
          let rowIndex = -1;

          initialIndex = dataGroupedCargas.findIndex(item => {
            // Procura o índice no array de 'rows'
            rowIndex = item.rows.findIndex(
              row => row.id === getRemoveSteps[0].conhecimentoPre.id
            );
            return rowIndex !== -1; // Se encontrou, retorna true para findIndex
          });

          onRemove(initialIndex, rowIndex);
        }}
        onChangeSteps={() => onReloadRoute()}
        onChangeAddress={(address: any, dataAddress: any) => {
          setStepsAddress(address);
          setKnowledgeAddress(dataAddress);
          setIsAddressChanged(!isAddressChanged);
        }}
        onCoordenates={(latitudeValue: number, longitudeValue: number) => {
          onMap(latitudeValue, longitudeValue);
        }}
        isAllowedDrag={isAllowedDrag}
        onDragEnd={(result, data: DataStepsRoutering[]) => {
          handleOnDragEnd(result, data, cargoData);
        }}
        onUpdate={() => onReloadRoute()}
      />
    </div>
  );
};

export default KnowledgeRouting;
