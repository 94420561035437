import { GroupedRow } from 'components/CreateCargo/types';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import { DataRouteringCargas } from 'store/modules/routering/actions';

// import { Container } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  data: GroupedRow[];
  onUpdateRow: () => void;
};

const CreateCargoDialog: React.FC<Props> = ({
  open,
  onClose,
  data,
  onUpdateRow,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState<any[]>([]);
  const [
    showConfirmationFreightFactorCreateCargo,
    setShowConfirmationFreightFactorCreateCargo,
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  // Function to remove duplicate elements from an array of objects
  function removeDuplicates(array: any[], key: keyof any): any[] {
    const seenKeys = new Set();

    // Use the filter method to create a new array without duplicate elements
    return array.filter(item => {
      // Get the value of the specified key from the object
      const keyValue = item[key];

      // Check if the key has been seen already
      if (!seenKeys.has(keyValue)) {
        // If it hasn't been seen, add it to the set and return true (keeping the item in the new array)
        seenKeys.add(keyValue);
        return true;
      }

      // If the key has been seen, return false (ignoring the item in the new array)
      return false;
    });
  }

  const onCreateCargo = useCallback(async (groupedRows: GroupedRow[]) => {
    try {
      setLoading(true);

      let selectedRows = removeDuplicates(groupedRows, 'simulation');

      for (const row of selectedRows) {
        console.log(row);
        const findDriver = row.teamList.find(
          (team: any) => team.type === 'DRIVER'
        );

        const data = {
          simulationId: row?.simulation,
          driverId: findDriver ? findDriver.id : null,
        };
        console.log(data);
        const response = await api.post('/delivery/romaneios/generate', data);

        if (response.status === 200) {
          const message = t('uploadCSV.messageCreated');

          // // Informa que a carga foi liberada no agrupamento.
          // if (row != null) {
          //   row.created = true;
          // }

          setLoading(false);

          onUpdateRow();

          FrontendNotification(
            'Rota criada com sucesso!',
            NotificationType.SUCCESS
          );
        }
      }

      window.open('/controlTower', '_blank');
      setLoading(false);
    } catch {}
  }, []);

  const onSubmit = useCallback(
    async (groupedRows: GroupedRow[], parameters: any[]) => {
      let selectedRows = removeDuplicates(groupedRows, 'simulation');

      for (const row of selectedRows) {
        const getListRouteOptmi: DataRouteringCargas[] = await getRouteOptmi(
          row.simulation
        );

        let findFreightFactorOptmi = null;

        findFreightFactorOptmi = getListRouteOptmi.find(
          (cargo: DataRouteringCargas) =>
            cargo.freightFactorPercentage > parameters[0].freightFactor
        );

        if (findFreightFactorOptmi !== null && findFreightFactorOptmi) {
          setShowConfirmationFreightFactorCreateCargo(
            !showConfirmationFreightFactorCreateCargo
          );
        }
      }
    },
    []
  );

  const getRouteOptmi = useCallback(async (simulationId: number) => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'simulation.id',
            value: simulationId,
            operation: 'EQUAL',
          },
          {
            field: 'inactivated',
            value: false,
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post('/route/optmis/findByFilters', body);

      setLoading(false);
      return response.data;
    } catch {}
  }, []);

  const getParameters = useCallback(async () => {
    try {
      const response = await api.get('/parameters/routing');

      setParameters(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    getParameters();
  }, [getParameters]);

  useEffect(() => {
    if (parameters.length > 0) {
      onSubmit(data, parameters);
    }
  }, [parameters]);

  return (
    <>

      <Modal isOpen={open} toggle={onClose}>
      <Loading loading={loading} />
        <ModalBody>
          <p className="tw-text-base tw-text-[#003049] tw-mb-0">
            {showConfirmationFreightFactorCreateCargo && (
              <pre className="tw-text-base tw-text-[#EA004C] tw-font-bold tw-mb-0">
                <Trans i18nKey="routeOptmization.simulation.msgUltrapassedFreightFactorCreateCargo" />
              </pre>
            )}
            <Trans i18nKey="routeOptmization.simulation.msgConfirmUltrapassedFreightFactorCreateCargo" />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => onCreateCargo(data)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={onClose} id="buttonCancelRemove">
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateCargoDialog;
