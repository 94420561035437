import { SelectionChangedEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { AgGridReact } from 'ag-grid-react';
import addVehicle from 'assets/images/addDriver.png';
import { Filter, RowSelection, SearchOperation } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import Totalizator from 'shared/Totalizator';
import {
  addAccumulatorRoutering,
  resetVehiclesAccumulator,
} from 'store/modules/accumulator/actions';
import Vehicles from '../../../../components/Vehicles';
import Checkbox from './components/Checkbox';
import Schedule from './components/Schedule';
import './styles.scss';

interface Props {
  onClear: () => void;
  onConfirm: (data: any[]) => void;
}

const SelectVehicles: React.FC<Props> = (props: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [columns] = useState<any[]>([
    {
      field: 'placa',
      headerName: 'Placa',
      checkboxSelection: true,
      filter: true,
      width: 130,
    },
    {
      field: '',
      headerName: 'RoundTrip',
      editable: true,
      width: 120,
      filter: true,
      cellRenderer: Checkbox,
      pinned: 'right',
      valueGetter: (params: any) => params.data.roundtrip, // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.roundtrip = params.newValue;

        return true;
      },
    },
    {
      field: 'disponivel',
      headerName: 'Disponível',
      width: 100,
    },

    {
      field: 'idReferencia',
      headerName: 'ID',
      width: 120,
    },
    {
      field: 'motorista.nome',
      headerName: 'Motorista',
    },
    {
      field: 'quantidadeAtendimento',
      headerName: 'Atend. máx',
      width: 110,
    },
    {
      field: 'peso',
      headerName: 'Peso',
      width: 100,
    },
    {
      field: 'cubagem',
      headerName: 'Cub. máx',
      width: 90,
    },
    {
      field: 'valor',
      headerName: 'Valor máx.',
      width: 100,
    },
    {
      field: '',
      headerName: 'Agendamento Saída',
      pinned: 'right',
      cellRenderer: Schedule,
      valueGetter: (params: any) => params.data.previsaoInicio,
      valueSetter: (params: any) => {
        params.data.previsaoInicio = params.newValue;

        console.log(params.data);
        return true;
      },
    },
  ]);
  const [filters] = useState<Filter[]>([
    {
      field: 'filial.id',
      value: sessionStorage.getItem('subsidiary'),
      operation: SearchOperation.EQUAL,
    },
    {
      field: 'motorista.status',
      value: 'ACTIVE',
      operation: SearchOperation.EQUAL,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [isOpenAddVehicle, setIsOpenAddVehicle] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onCalcAccumulatorVehicles = useCallback(() => {
    const totalAtend = selectedRows.reduce(
      (total, item: any) => total + item.quantidadeAtendimento,
      0
    );
    const totalPeso = selectedRows.reduce(
      (total, item: any) => total + item.peso,
      0
    );
    const totalCubagem = selectedRows.reduce(
      (total, item: any) => total + item.cubagem,
      0
    );
    const totalValor = selectedRows.reduce(
      (total, item: any) => total + item.valor,
      0
    );

    dispatch(resetVehiclesAccumulator());

    dispatch(
      addAccumulatorRoutering(totalAtend, totalPeso, totalCubagem, totalValor)
    );
  }, [selectedRows]);

  const onGridReady = async (params?: any) => {
    if(params) {
      setGridApi(params.api);
    }

    const reqDTO = {
      filters: filters,
      orders: [],
      size: 1000,
      page: 0,
    };

    const response = await api.post('/delivery/veiculos/pageable', reqDTO);

    const rows = response.data.content.map((item: any) => {
      return {
        ...item,
        roundtrip: true,
        previsaoInicio: null,
      };
    });

    // props.setRowData?.(response.data.content);
    // console.log(response.data.content);
    setRowData(rows);

    if (gridRef.current) {
      const api = gridRef.current.api;
      // api.setColumnFilterModel()
      await api.setColumnFilterModel('status', {
        filterType: 'text',
        type: 'equals',
        filter: 'ACTIVE',
      });
      api.onFilterChanged();
    }
  };

  useEffect(() => {
    onCalcAccumulatorVehicles();
  }, [selectedRows]);

  return (
    <CustomModal
      isOpen
      label={t('selectVehicles.title')}
      isClose={props.onClear}
      styles={{ minWidth: '92%' }}
    >
      <div className="tw-w-full">
        {isOpenAddVehicle && (
          <Vehicles
            isNew
            onClear={() => {
              setIsOpenAddVehicle(!isOpenAddVehicle);
              onGridReady();
            }}
          />
        )}
        <div className="tw-flex tw-items-start tw-justify-between">
          <div>
            <button
              className="tw-bg-[#EA004C] tw-rounded-md tw-border-none tw-p-1.5 tw-flex tw-items-center tw-justify-center tw-text-[#fff] tw-text-sm tw-mb-3 tw-mt-0"
              onClick={() => setIsOpenAddVehicle(!isOpenAddVehicle)}
            >
              <img src={addVehicle} alt="" className="tw-mr-2" />
              <Trans i18nKey="selectVehicles.vehicleTitle" />
            </button>
          </div>
          <div className="tw-flex tw-flex-col">
            <Totalizator />

            <div className="tw-flex tw-flex-col tw-items-end tw-justify-end tw-mt-4 tw-mb-2 tw-mr-8">
              <label className="tw-text-[#181D1F] tw-text-sm tw-text-start tw-w-full tw-font-bold">
                Agendamento Geral
              </label>
              <input
                className=""
                type="datetime-local"
                onChange={(e: any) => {
                  if (e.target.value.length > 0) {
                    const rows = rowData;

                    const updatedRows = rows?.map((row: any) => ({
                      ...row,
                      previsaoInicio: e.target.value,
                    }));

                    gridRef.current?.api.applyTransaction({
                      update: updatedRows,
                    });

                    if (updatedRows) {
                      // setSelectedRows(updatedRows);
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="ag-theme-quartz tw-flex !tw-w-full tw-h-96">
          <Row className="ag-theme-quartz tw-h-96">
            <Col md={12}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                getRowId={params => params.data.id}
                columnDefs={columns}
                pagination
                onSelectionChanged={(params: SelectionChangedEvent) => {
                  const rows: any[] = params.api.getSelectedRows();
                  setSelectedRows(rows);

                  params.api.refreshCells({
                    force: true,
                  });
                }}
                rowSelection={RowSelection.MULTIPLE}
                onGridReady={onGridReady}
                suppressRowClickSelection
              />
            </Col>
          </Row>
        </div>
        <div className="tw-flex tw-justify-end tw-items-center !tw-mt-0">
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-w-32 tw-h-10 tw-text-sm tw-text-[#FFF] tw-rounded-md tw-p-2 tw-mr-7"
            onClick={() => {
              if (selectedRows.length > 0) {
                const findScheduleDateByVehicle = selectedRows.find(
                  (item: any) =>
                    item.previsaoInicio !== null &&
                    item.previsaoInicio.length > 0
                );

                props.onConfirm(selectedRows);

                // if (findScheduleDateByVehicle) {
                //   props.onConfirm(selectedRows);
                // } else {
                //   FrontendNotification(
                //     'Preencha os campos corretamente!',
                //     NotificationType.WARNING
                //   );
                // }

                console.log(findScheduleDateByVehicle);
              } else {
                FrontendNotification(
                  'Selecione pelo menos um veículo!',
                  NotificationType.WARNING
                );
              }
            }}
          >
            Roteirizar
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SelectVehicles;
