import { ValueFormatterParams } from 'ag-grid-community';
import Grid from 'components/Grid/Grid';
import {
  ColumnDef,
  Direction,
  Filter,
  Order,
  RowSelection,
  SearchOperation,
} from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import history from 'services/history';
import { formatDateTime } from 'util/format';
import { CargoNumberFilter } from './Filter/CargoNumberFilter';
import { DateFilter } from './Filter/DateFilter';
import { DescriptionFilter } from './Filter/DescriptionFilter';
import { DriverFilter } from './Filter/DriverFilter';
import { SimulationDescriptionFilter } from './Filter/SimulationDescriptionFilter';
import { UserFilter } from './Filter/UserFilter';
import './styles.scss';
import { IOptmis } from './types';

interface Props {
  onClear: () => void;
}

const RouteringSimulations: FC<Props> = ({ onClear }) => {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const dateFormatter = (params: ValueFormatterParams) => {
    if (params.data != null && params.data.dataCriacao != null) {
      return formatDateTime(params.data.dataCriacao);
    }
    return '';
  };

  const [columns] = useState<ColumnDef[]>([
    {
      field: 'simulation.description',
      headerName: 'Nome da simulação',
      filter: SimulationDescriptionFilter,
    },
    {
      field: 'dataCriacao',
      headerName: 'Data',
      valueFormatter: dateFormatter,
      filter: DateFilter,
    },
    {
      field: 'referenceId',
      headerName: 'Nº da Carga',
      // valueFormatter/: userFormatter,
      filter: CargoNumberFilter,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      // valueFormatter/: userFormatter,
      filter: DescriptionFilter,
    },
    {
      field: 'simulation.user.nome',
      headerName: 'Usuário',
      filter: UserFilter,
    },
    {
      field: 'driver.nome',
      headerName: 'Motorista',
      filter: DriverFilter,
    },
    {
      field: 'recurring',
      headerName: 'Rota Recorrente',
      cellDataType: 'boolean',
      // valueFormatter: (params) => (params.value ? 'Sim' : 'Não'),
      sortable: true,
      comparator: (a: any, b: any) => {
        if(a === b) return 0;

        return a ? 1 : -1;
      }
    },
  ]);
  const [selectedRow, setSelectedRow] = useState<IOptmis[]>([]);
  const [filters] = useState<Filter[]>([
    {
      field: 'inactivated',
      operation: SearchOperation.EQUAL,
      value: false,
    },
    // { field: 'status', value: 'ACTIVE', operation: SearchOperation.EQUAL },
  ]);
  const [orders] = useState<Order[]>([
    { field: 'dataModificacao', direction: Direction.DESC },
  ]);

  const [isRemove, setIsRemove] = useState(false);

  const deleteSimulations = useCallback(async (id: number) => {
    try {
      setLoading(true);
      const response = await api.delete(`/route/optmis/${id}`);

      if (response.status === 204) {
        FrontendNotification(
          t('Simulação removida com sucesso'),
          NotificationType.SUCCESS
        );
      }
      setIsRemove(false);
      setSelectedRow([]);
      setLoading(false)
      // getSimulations();
    } catch (err) {
      // BackendErrorNotification(err);
    }
  }, []);

  function onUpdate() {
    if (selectedRow.length > 0) {
      sessionStorage.setItem(
        'idSimulacao',
        String(selectedRow[0].simulation.id)
      );
      // dispatch(addSimulatorRouteOptmi(item.id));
      // dispatch(editSimulatorRoute(true));
      history.push('/routering');
      window.location.reload();
    }
  }

  function onDelete() {
    if (selectedRow.length > 0) {
      setIsRemove(!isRemove);
    }
  }

  function onCreate() {
    history.push('/uploadCSV');
    window.location.reload();
  }



  return (
    <CustomModal
      isOpen
      isClose={onClear}
      label="Simulações"
      styles={{ minWidth: '800px' }}
    >
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => deleteSimulations(selectedRow[0].id)}
      />
      <div className="tw-h-96 tw-mb-4">
        {loading ? (
            <Loading loading={loading} />
        ) : (

        <Grid<IOptmis>
          force={refresh}
          columns={columns}
          pagination={true}
          perPage={1000}
          rowSelection={RowSelection.SINGLE}
          path="/route/optmis/pageable/summary"
          selectedRows={selectedRow}
          setSelectedRows={setSelectedRow}
          onUpdate={(data?: any) => {
            let rows = [];
            rows.push(data);
            setSelectedRow(rows);
            sessionStorage.setItem(
              'idSimulacao',
              String(data.simulation.id)
            );
            history.push('/routering');
            window.location.reload();
          }}
          onDelete={(data?: any) => {
            let rows = [];
            rows.push(data);
            setSelectedRow(rows);
            setIsRemove(!isRemove)
          }}
          customCrudButtons
          showCrudButtons={false}
          filters={filters}
          orders={orders}
          server={true}
        />
      )}

      </div>
    </CustomModal>
  );
};

export default RouteringSimulations;
