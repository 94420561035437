import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'fastDelivery',
      storage: sessionStorage,
      whitelist: [
        'auth',
        'map',
        'reasons',
        'csvMapping',
        'routeringSimulation',
        'selectedVehicles',
        'kpis',
        'reports',
        'driverScheduling',
        'routering',
        'formationCargas',
        'socket'
      ],
    },
    reducers
  );

  return persistedReducer;
};
