import produce from "immer";

interface ActionReducer {
  type: string;
  payload: any;
};

const INITIAL_STATE = {
  data: [],
};

export default function formationCargas(state = INITIAL_STATE, action: ActionReducer) {
  return produce(state, draft => {
    switch (action.type) {
      case '@formationCargas/REMOVE_GROUPED_CARGAS': {
        draft.data.splice(action.payload.index, 1)
        break;
      }
      case '@formationCargas/SET_GROUPED_CARGAS': {
        draft.data = action.payload.data
        break;
      }
      default:
    }
  })
}