import {
  GetRowIdParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { DetailedRow } from 'components/CreateCargo/types';
import { RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import { FC, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Resizable } from 'react-resizable';
import { ButtonGroup, Col, Row } from 'reactstrap';

interface Props {
  onClose: () => void;
  onTransfer: () => void;
  onRemove: () => void;
  onRemoveAll: () => void;
  onOpenChangeAddress: () => void;
  onCreate: () => void;
  onSelectAll: () => void;
  isTransferMenuDisabled: boolean;
  gridRef: any;
  gridColumns: any[];
  gridData: DetailedRow[];
}

const DetailedCargos: FC<Props> = (props: Props) => {
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(400);

  const { t } = useTranslation();


  // Define o tamanho do modal da tela de atendimentos.
  const onResize = (data: any) => {
    setWidth(data.size.width);
    setHeight(data.size.height);
  };

  return (
    <Resizable
          width={width}
          height={height}
          onResize={(e: any, data: any) => onResize(data)}
          draggableOpts={{ grid: [20, 20] }}
        >
          <CustomModal
            isClose={props.onClose}
            label=""
            isOpen
            styles={{ minWidth: width, minHeight: height }}
            buttons={
              <DropdownButton
                as={ButtonGroup}
                title={t('actions')}
                className="dropdown-custom"
              >
                <Dropdown.Item
                  className="btn-default"
                  eventKey="transfer"
                  onClick={() => props.onTransfer()}
                  disabled={props.isTransferMenuDisabled}
                >
                  {t('action.transfer')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="btn-default"
                  eventKey="remove"
                  onClick={() => props.onRemove()}
                >
                  {t('action.remove')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="btn-default"
                  eventKey="remove"
                  onClick={() => props.onRemoveAll()}
                >
                  {t('action.removeAll')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="btn-default"
                  eventKey="remove"
                  onClick={() => props.onSelectAll()}
                >
                  {t('action.selectAll')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="btn-default"
                  eventKey="remove"
                  onClick={() => props.onOpenChangeAddress()}
                >
                  {t('action.changeAddress')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="btn-default"
                  eventKey="create"
                  onClick={() => props.onCreate()}
                >
                  {t('action.detailCreate')}
                </Dropdown.Item>
              </DropdownButton>
            }
          >
            <Row style={{ height: '500px' }}>
              <Col md="12" className="ag-theme-quartz h-100 mb-0">
                <AgGridReact
                  ref={props.gridRef}
                  rowData={props.gridData}
                  columnDefs={props.gridColumns}
                  getRowId={(params: GetRowIdParams) => {
                    return params.data.id;
                  }}
                  pagination={true}
                  rowSelection={RowSelection.MULTIPLE}
                />
              </Col>
            </Row>
          </CustomModal>
        </Resizable>
  )
}

export default DetailedCargos;