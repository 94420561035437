/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-const */
import { Content } from 'components/Phases/styles';
import { useState } from 'react';
import { Modal } from 'reactstrap';
import './styles.scss';

import { Trans } from 'react-i18next';
import closeModalIcon from '../../assets/images/closeIcon.svg';

export default function PhasesSMS({ onClear, message }) {
  const [modal, setModal] = useState(true);

  function loadTags() {
    let text = message;
    let removeColchetes = text.replace(/(\[)/g, '{');
    let removeColchetes2 = removeColchetes.replace(/(\])/g, '}');
    // let email = text.replace(/.*{(.*)}.*/, '$1');
    let regex = new RegExp('{([^{}]+)}', 'g');
    let result;
    result = regex.exec(removeColchetes2);
    let str;
    result.map(item => {
      str = removeColchetes.replace(
        item,
        `<span class='AlteraCor'>${item}</span>`
      );
    });
    document.createElement('span');
    // document.getElementById('result').innerHTML = str;
  }

  function clearNav() {
    onClear();
    setModal(false);
  }

  return (
    <>
      <Modal
        size="lg"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '50%' }}
      >
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 90 }}
        >
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p
            className="title"
            style={{ fontSize: 24, fontWeight: 'bold', color: '#003049' }}
          >
            <Trans i18nKey="phases.smsPhases" />
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <div className="d-flex">
            <Content style={{ width: '100%' }}>
              <span className="titleMessage">
                <Trans i18nKey="phases.smsMessage" />
              </span>
              {/* <div style={{ position: 'relative', right: 15 }}>
                <Tags
                  value={message}
                  // autoFocus
                  InputMode="textarea"
                  // onChange={onChangeTag}
                  readOnly
                  // autoBlur
                  styles={{
                    border: 'none',
                    borderColor: 'transparent',
                  }}
                />
              </div> */}
            </Content>
          </div>
        </div>
      </Modal>
    </>
  );
}
