import { SearchOperation } from 'components/Grid/types';
import { Branch } from 'components/SubsidiaryRegister/interfaces/types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import TopBar from 'util/TopBar';
import BoxSection from './components/BoxSection';
import ReportPunctiality from './components/ReportPunctiality';

// import { Container } from './styles';

const Dashboard: React.FC = () => {
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);

  const initialDayOfMonth = new Date();

  const [filters, setFilters] = useState([
    {
      field: 'romaneio.data',
      value: format(
        initialDayOfMonth.setDate(initialDayOfMonth.getDate() - 130),
        'yyyy-MM-dd',
        {
          locale: ptBR,
        }
      ),
      operation: SearchOperation.GREATHER_THAN_EQUAL,
    },
    {
      field: 'romaneio.data',
      value: format(new Date(), 'yyyy-MM-dd', {
        locale: ptBR,
      }),
      operation: SearchOperation.LESS_THAN_EQUAL,
    },

    {
      field: 'ocorrencia',
      operation: SearchOperation.IS_NOT_NULL,
    },
    {
      field: 'step.conhecimentoPre.clienteHorarioAbertura',
      value: '',
      operation: SearchOperation.NOT_EQUAL,
    },
    {
      field: 'step.conhecimentoPre.clienteHorarioFechamento',
      value: '',
      operation: SearchOperation.NOT_EQUAL,
    },

    {
      field: 'step.conhecimentoPre.clienteHorarioAbertura',
      operation: SearchOperation.IS_NOT_NULL,
    },
    {
      field: 'step.conhecimentoPre.clienteHorarioFechamento',
      operation: SearchOperation.IS_NOT_NULL,
    },
  ]);

  const [branchs, setBranchs] = useState<Branch[]>([]);

  const getBranchs = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/filiais');

      setBranchs(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    getBranchs();
  }, [getBranchs]);

  return (
    <>
      <TopBar listSubsidiary={branchs} onChangeSubsidiary={() => {}} />
      
      <div className="tw-w-full tw-p-4">
        <BoxSection
          title="Pontualidade"
          onOpenFilters={() => setIsOpenFilters(!isOpenFilters)}
        >
          <ReportPunctiality filters={filters} isOpenFilters={isOpenFilters} onClear={() => setIsOpenFilters(!isOpenFilters)} />
        </BoxSection>
      </div>
    </>
  );
};

export default Dashboard;
