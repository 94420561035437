/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-const */
import { Content } from 'components/Phases/styles';
import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import './styles.scss';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Trans } from 'react-i18next';
import api from 'services/api';
import closeModalIcon from '../../../assets/images/closeIcon.svg';

export default function SMSMessage({ data, etapaId, knowledgeId, onClear }) {
  const [modal, setModal] = useState(true);
  const [phases, setPhases] = useState([]);
  const [knowledgeSMS, setKnowledgeSMS] = useState([]);

  const getPhases = useCallback(async () => {
    const dataFilter = {
      filters: [
        {
          field: 'id',
          value: etapaId,
          operation: 'EQUAL',
        },
      ],
    };
    const response = await api.post(
      '/delivery/etapas/findByFilters',
      dataFilter
    );

    setPhases(response.data);
  }, []);
  const getSmsPhases = useCallback(async () => {
    try {
      const dataSms = {
        filters: [
          {
            field: 'etapa.id',
            value: etapaId,
            operation: 'EQUAL',
          },
          {
            field: 'conhecimento.id',
            value: knowledgeId,
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/conhecimentosSms/findByFilters',
        dataSms
      );

      setKnowledgeSMS(response.data);
    } catch (err) {}
  }, []);

  function clearNav() {
    onClear();
  }

  useEffect(() => {
    getPhases();
    getSmsPhases();
  }, [getPhases, getSmsPhases]);

  return (
    <>
      <Modal
        size="lg"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '50%' }}
      >
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 90 }}
        >
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p
            className="title"
            style={{ fontSize: 24, fontWeight: 'bold', color: '#003049' }}
          >
            <Trans i18nKey="phases.smsPhases" />:
            {phases.length > 0 && phases[0].descricao}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <div className="d-flex">
            <Content>
              <span
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  color: '#003049',
                  marginBottom: 23,
                }}
              >
                {knowledgeSMS.length > 0 && knowledgeSMS[0].sms.mensagem} <br />{' '}
                <br />
              </span>
              <span
                style={{
                  fontFamily: 'Lato',
                  fontSize: 12,
                  color: '#003049',
                  marginBottom: 23,
                }}
              >
                {i18next.language === 'pt' || i18next === 'pt-BR'
                  ? `SMS enviado em ${format(
                      new Date(data[0].sms.dataEnvio),
                      'dd/MM/yyyy',
                      pt
                    )} ás ${format(
                      new Date(data[0].sms.dataEnvio),
                      'HH:mm',
                      pt
                    )} para ${data[0].sms.numero}`
                  : `SMS sent on ${format(
                      new Date(data[0].sms.dataEnvio),
                      'dd/MM/yyyy',
                      pt
                    )} at ${format(
                      new Date(data[0].sms.dataEnvio),
                      'HH:mm:ss',
                      pt
                    )} to ${data[0].sms.numero}`}
              </span>
            </Content>
          </div>
        </div>
      </Modal>
    </>
  );
}
