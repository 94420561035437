// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recharts-legend-wrapper {
  bottom: -40px !important;
}

.recharts-wrapper {
  /* width: 100% !important; */
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/components/ReportPunctiality/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".recharts-legend-wrapper {\n  bottom: -40px !important;\n}\n\n.recharts-wrapper {\n  /* width: 100% !important; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
