import { DataComponentField } from 'components/CustomFilterDialog';
import CustomModal from 'components/ModalCustom';
import { FormFieldShipment } from 'pages/ControlTower';
import React, { useEffect, useState } from 'react';
import Input from '../Input';

interface Props {
  onClear: () => void;
  onFilter: (dataFilters: any) => void;
  filtersData: any;
  data: any;
}

const Filters: React.FC<Props> = (props: Props) => {
  const dateInitial = new Date();

  const [dataJsonComponent, setDataJsonComponent] = useState<any>([]);
  const [dataJson, setDataJson] = useState<any>([]);

  const [dataFilters, setDataFilters] = React.useState<DataComponentField[]>(
    []
  );
  const [textFilter, setTextFilter] = useState('');
  const [fields, setFields] = useState<FormFieldShipment>({});

  useEffect(() => {
    if (props.filtersData) {
      setDataJsonComponent(JSON.parse(props.filtersData));
    }
    if (props.data) {
      setDataJson(JSON.parse(props.data));
      setDataFilters(JSON.parse(props.data));
    }
  }, [props.filtersData, props.data]);

  return (
    <CustomModal
      isOpen
      label="Filtros"
      isClose={props.onClear}
      styles={{ minWidth: '800px' }}
    >
      <div className="tw-grid tw-grid-cols-3 tw-gap-3">
        {dataJsonComponent.map((field: DataComponentField, index: number) => (
          <>
            {field.type === 'date' ? (
              <div className="tw-flex tw-flex-col">
                <Input
                  placeholder={field.placeholder}
                  type={field.type}
                  title={field.label}
                  onFocus={(e: any) => {
                    setTextFilter(field.value);
                  }}
                  onChange={e => {
                    setTextFilter(e.target.value);

                    dataJson[field.field] = e.target.value;

                    setDataFilters(dataJson);
                  }}
                  value={dataJson[field.field]}
                />
              </div>
            ) : (
              <div className="tw-flex tw-flex-col">
                <Input
                  placeholder={field.placeholder}
                  type={field.type}
                  title={field.label}
                  onFocus={(e: any) => {
                    setTextFilter(field.value);
                  }}
                  onChange={e => {
                    setTextFilter(e.target.value);

                    dataJson[field.field] = e.target.value;

                    setDataFilters(dataJson);
                  }}
                  value={dataJson[field.field]}
                />
              </div>
            )}
          </>
        ))}
      </div>
      <div className="tw-w-full tw-flex tw-justify-end">
        <button
          className="button !tw-mr-0 tw-max-w-[200px]"
          type="button"
          onClick={() => {
            console.log(dataFilters);
            sessionStorage.setItem(
              'reports::punctualityData',
              JSON.stringify(dataFilters)
            );
            props.onFilter(dataFilters);
          }}
        >
          Filtrar
        </button>
      </div>
    </CustomModal>
  );
};

export default Filters;
