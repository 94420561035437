/* eslint-disable */

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ApolloProvider } from '@apollo/client';
import client from 'services/graphql';
import Loading from './core/common/Loading';
import './i18n';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading loading />}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
