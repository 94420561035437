import Grid from 'components/Grid/Grid';
import { ColumnDef, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Create from './components/Create';
import { IVehicles } from './interfaces/types';

interface Props {
  onClear: () => void;
  isNew: any;
}

const Vehicles: React.FC<Props> = (props: Props) => {

  const [columns, setColumns] = useState<ColumnDef[]>([
    {
      field: 'placa',
      headerName: 'Placa',
      filter: true,
      width: 120,
    },
    {
      field: 'motorista.nome',
      headerName: 'Motorista',
      filter: true,
    },
    {
      field: 'idReferencia',
      headerName: 'Id de referência',
      filter: true,
      width: 160,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      filter: true,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      filter: true,
    },
    {
      field: 'veiculoProprio',
      headerName: 'Veiculo Próprio',
      filter: true,
    },
  ]);
  const [tabs, setTabs] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<IVehicles[]>([]);

  const { t } = useTranslation();

  return (
    <CustomModal
      isOpen
      label={t('vehicles.titleMenu')}
      isClose={props.onClear}
      styles={{ minWidth: '80%' }}
    >
      <Tabs selectedIndex={tabs} onSelect={(index: number) => setTabs(index)}>
        <TabList>
          <Tab>
            <Trans i18nKey="tabs.list" />
          </Tab>
          <Tab>
            <Trans i18nKey="tabs.new" />
          </Tab>
          <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
        </TabList>
        <TabPanel>
          <div className="tw-flex tw-w-full tw-h-96">
            <Grid<IVehicles>
              
              columns={columns}
              filters={[]}
              pagination
              path="/delivery/veiculos/pageable"
              showCrudButtons={false}
              customCrudButtons
              // setSelectedRows={setSelectedRow}
              rowSelection={RowSelection.SINGLE}
              onUpdate={(data?: IVehicles) => {
                let rows: any = [];
                rows.push(data);
                setSelectedRow(rows);
                setTabs(2);
              }}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <Create
            isNew={props.isNew}
            onConfirm={() => {
              if (props.isNew) {
                props.onClear();
              } else {
                setTabs(0);
              }
            }}
          />
        </TabPanel>
        <TabPanel>
          <Create
            isNew={props.isNew}
            isEdit
            selectedRow={selectedRow[0]}
            onConfirm={() => {
              setTabs(0);
            }}
          />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default Vehicles;
