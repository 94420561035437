import Grid from 'components/Grid/Grid';
import {
  ColumnDef,
  Filter,
  RowSelection,
  SearchOperation,
} from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/LoadingRoutering';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import { DataRouteringCargas } from 'store/modules/routering/actions';
import ConfirmationRouteringCapacity from '../ConfirmRouteringCapacity';

// import { Container } from './styles';

interface Props {
  onClear: () => void;
  data: DataRouteringCargas[];
  parameters: any[];
}

type RouteOptmi = {
  id: string;
  driver: {
    id: string;
    nome: string;
  };
  steps: number;
};

const ReleaseLoading: React.FC<Props> = ({
  onClear,
  data,
  parameters,
}: Props) => {
  const dataCargoRoutering: DataRouteringCargas[] = useSelector(
    (state: any) => state.routering.data.dataOptmi
  );

  const [
    showConfirmationFreightFactorReleaseLoading,
    setShowConfirmationFreightFactorReleaseLoading,
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  const [columns] = useState<ColumnDef[]>([
    {
      field: 'id',
      headerName: 'Nº Rota',
      checkboxSelection: true,
      filter: true,
    },
    {
      field: 'driver.nome',
      headerName: 'Motorista',
      filter: true,
    },
    {
      field: 'steps',
      headerName: 'Atendimentos',
      valueFormatter(params) {
        return params.value.length;
      },
    },
  ]);
  const [rowData, setRowData] = useState<RouteOptmi[]>([]);
  const [selectedRows, setSelectedRows] = useState<RouteOptmi[]>([]);
  const [filters] = useState<Filter[]>([
    {
      field: 'simulation.id',
      value: String(dataCargoRoutering[0].simulation.id),
      operation: SearchOperation.EQUAL,
    },
    {
      field: 'inactivated',
      value: false,
      operation: SearchOperation.EQUAL,
    },
  ]);
  const [showCreateCargoCapacity, setShowCreateCargoCapacity] = useState<
    boolean
  >(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onCreateRouteCargo = useCallback(async (dataRoute: RouteOptmi[]) => {
    try {
      setLoading(true);

      const dataCreateRoute = dataRoute.map((route: RouteOptmi) => {
        return {
          optmization: {
            id: route.id,
          },
        };
      });

      const data = {
        routes: dataCreateRoute,
      };

      const response = await api.post('/route/cargo/create', data);

      if (response.status === 204) {
        setTimeout(() => {

          setLoading(false);
        }, 3000)
        onClear();
      }

      setTimeout(() => {

        setLoading(false);
      }, 3000)
    } catch (err) {
      setLoading(false);
    }
  }, []);

  function calcPorcentage(valueInitial: number, total: number) {
    const md_val: any = Number(valueInitial).toFixed(2);
    const medidab: any = Number(md_val / total) * 100;
    const formatMedidab: any = parseInt(medidab);
    return formatMedidab;
  }

  function getCalcPorcentage(dataAccumulator: any) {
    const accumulatorAttendances = calcPorcentage(
      dataAccumulator.steps.length,
      dataAccumulator.vehicle.quantidadeAtendimento
    );

    const accumulatorWeight = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.peso);
      },
      0
    );

    const accumulatorVolume = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return (
          acc +
          parseFloat(
            itemAcc.conhecimentoPre.cubagem.length === 0
              ? 0
              : itemAcc.conhecimentoPre.cubagem
          )
        );
      },
      0
    );

    const accumulatorValor = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.valor);
      },
      0
    );

    return {
      porcentageAttendances: accumulatorAttendances,
      porcentageWeight: calcPorcentage(
        accumulatorWeight,
        dataAccumulator.vehicle.peso
      ),
      porcentageVolume: calcPorcentage(
        accumulatorVolume,
        dataAccumulator.vehicle.cubagem
      ),
      porcentagePrice: calcPorcentage(
        accumulatorValor,
        dataAccumulator.vehicle.valor
      ),
    };
  }

  return (
    <CustomModal
      isOpen
      isClose={onClear}
      label={t('releaseLoading.title')}
      styles={{ maxWidth: '600px !important' }}
    >
      <>
        <Loading loading={loading} />
        {!showCreateCargoCapacity && showConfirmationFreightFactorReleaseLoading && (
          <Modal
          isOpen={showConfirmationFreightFactorReleaseLoading}
          toggle={() =>
            setShowConfirmationFreightFactorReleaseLoading(
              !showConfirmationFreightFactorReleaseLoading
            )
          }
          >
          <Loading loading={loading} />
            <ModalBody>
            <p className="tw-text-base tw-text-[#003049] tw-mb-0">
                <pre className="tw-text-base tw-text-[#EA004C] tw-font-bold tw-mb-0">
                  <Trans i18nKey="routeOptmization.simulation.msgUltrapassedFreightFactorReleaseLoading" />
                </pre>
                <Trans i18nKey="routeOptmization.simulation.msgConfirmUltrapassedFreightFactorReleaseLoading" />
              </p>
              
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => onCreateRouteCargo(selectedRows)}
                id="buttonSaveRemove"
              >
                <Trans i18nKey="common.confirmRemove" />
              </Button>{' '}
              <Button
                color="secondary"
                onClick={() =>
                  setShowConfirmationFreightFactorReleaseLoading(
                    !showConfirmationFreightFactorReleaseLoading
                  )
                }
                id="buttonCancelRemove"
              >
                <Trans i18nKey="common.cancelRemove" />
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {showCreateCargoCapacity && (
          <ConfirmationRouteringCapacity
            cargas={selectedRows.length}
            showFactorFreightUltrapassed={showConfirmationFreightFactorReleaseLoading}
            onClear={() => setShowCreateCargoCapacity(!showCreateCargoCapacity)}
            onConfirm={() => {
              setShowCreateCargoCapacity(!showCreateCargoCapacity);

              onCreateRouteCargo(selectedRows);
            }}
          />
        )}
        <Row className="tw-mb-2 tw-h-80">
          <Col md="12">
            {/* Grid com os pré-conhecimentos. */}
            <Grid<RouteOptmi>
              columns={columns}
              pagination={true}
              rowSelection={RowSelection.MULTIPLE}
              path="/route/optmis/pageable"
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              showCrudButtons={false}
              filters={filters}
              setRowData={setRowData}
            />
          </Col>
        </Row>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mb-2 tw-mt-2">
          <button
            className="tw-flex tw-items-center tw-justify-center tw-w-1/4 tw-h-8 tw-bg-[#10841C] tw-p-2 tw-border-none tw-text-[#fff] tw-font-bold tw-rounded-lg"
            onClick={() => {
              const selectedRowsRouting = selectedRows.map((routing: any) => {
                return {
                  ...getCalcPorcentage(routing),
                };
              });

              const capacityAttendances = selectedRowsRouting.find(
                (item: any) => item.porcentageAttendances > 100
              );
              const capacityWeight = selectedRowsRouting.find(
                (item: any) => item.porcentageWeight > 100
              );
              const capacityCubage = selectedRowsRouting.find(
                (item: any) => item.porcentageVolume > 100
              );
              const capacityPrice = selectedRowsRouting.find(
                (item: any) => item.porcentagePrice > 100
              );

              if (
                capacityAttendances ||
                capacityWeight ||
                capacityCubage ||
                capacityPrice
              ) {
                const findFreightFactorOptmi = data.find(
                  (cargo: DataRouteringCargas) =>
                    cargo.freightFactorPercentage > parameters[0].freightFactor
                );

                if (findFreightFactorOptmi) {
                  setShowConfirmationFreightFactorReleaseLoading(
                    !showConfirmationFreightFactorReleaseLoading
                  );
                } 

                setShowCreateCargoCapacity(!showCreateCargoCapacity);
              } else {
                const findFreightFactorOptmi = data.find(
                  (cargo: DataRouteringCargas) =>
                    cargo.freightFactorPercentage > parameters[0].freightFactor
                );

                if (findFreightFactorOptmi) {
                  setShowConfirmationFreightFactorReleaseLoading(
                    !showConfirmationFreightFactorReleaseLoading
                  );
                } else {
                  onCreateRouteCargo(selectedRows);
                }
              }
            }}
          >
            <Trans i18nKey="common.confirmRemove" />
          </button>
        </div>
      </>
    </CustomModal>
  );
};

export default ReleaseLoading;
