import { Tooltip } from '@mui/material';
import { SelectionChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import ContentCopyIcon from 'assets/images/contentCopy.svg';
import deleteIcon from 'assets/images/deleteIcon.svg';
import editIcon from 'assets/images/editIcon.svg';
import PublishIcon from 'assets/images/publishIcon.svg';
import ViewIcon from 'assets/images/viewIcon.svg';
import { ColumnDef, Filter, SearchOperation } from 'components/Grid/types';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import IShippingTable from './interfaces/IShippingTable';
import { Container } from './styles';

interface ListShippingTableProps {
  data: IShippingTable[];
  totalPages: number;
  currentPage: number;
  onCreated: () => void;
  onRemove: (data: any) => void;
  onUpdate: (data: any) => void;
  onCopy: (data: any) => void;
  onPublish: (data: any) => void;
  onView: (data: any) => void;
  onChangePage: (page: number) => void;
  selectedRow: IShippingTable[];
  setSelectedRow: any;
}

const ListShippingTable: React.FC<ListShippingTableProps> = ({
  data,
  totalPages,
  currentPage,
  onCreated,
  onRemove,
  onUpdate,
  onCopy,
  onPublish,
  onView,
  onChangePage,
  selectedRow,
  setSelectedRow,
}: ListShippingTableProps) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [columns] = useState([
    {
      field: 'descricao',
      headerName: 'Nome da tabela',
      filter: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.value === 'ACTIVE') {
            return 'ATIVO';
          }
          return 'INATIVO';
        }
        return '';
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo da tabela',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        console.log(params);
        if (params.value) {
          return params.value.replace('VALOR_FIXO', 'Valor Fixo');
        }
        return '';
      },
    },
    {
      field: 'dataInicial',
      headerName: 'Vigência',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        console.log(params);
        if (params.value) {
          return `${params.data.dataInicial} - ${params.data.dataFinal}`;
        }
        return '';
      },
    },
    {
      field: 'publicada',
      headerName: 'Publicada',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.value === true) {
            return 'SIM';
          } else {
            return 'NÃO';
          }
          // return params.value === true ? "SIM" : params.value === false ? "NAO" : ""
        }
        return 'NÃO';
      },
    },
  ]);
  const [colDefs, setColDefs] = useState<any>([]);
  const [rowData, setRowData] = useState();
  '';
  const [gridApi, setGridApi] = useState<any>(null);
  const [filters] = useState<Filter[]>([
    {
      field: 'status',
      value: ['INACTIVE', 'ACTIVE'],
      operation: SearchOperation.IN,
    },
  ]);

  const [crudButtons] = useState<ColumnDef[]>([
    {
      field: '',
      headerName: '',
      pinned: 'right',
      cellRenderer: (params: CustomCellRendererProps) => {
        if (params.data) {
          return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
              <div
                className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-full tw-mr-3"
                id="updated"
                onClick={() => onUpdated(params.data)}
              >
                <Tooltip title="Editar" placement="top">
                  <img src={editIcon} />
                </Tooltip>
              </div>
              <div
                className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-full tw-mr-3"
                id="delete"
                onClick={() => onDelete(params.data)}
              >
                <Tooltip title="Remover" placement="top">
                  <img src={deleteIcon} />
                </Tooltip>
              </div>

              <div
                className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-full tw-mr-3"
                id="delete"
                onClick={() => onCopyed(params.data)}
              >
                <Tooltip title="Copiar" placement="top">
                  <img
                    src={ContentCopyIcon}
                    className="tw-mr-1 tw-w-4 tw-h-4"
                  />
                </Tooltip>
              </div>
              <div
                className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-full tw-mr-3"
                id="delete"
                onClick={() => onPublished(params.data)}
              >
                <Tooltip title="Publicar" placement="top">
                  <img src={PublishIcon} className="tw-mr-1 tw-w-5 tw-h-5" />
                </Tooltip>
              </div>
              <div
                className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-full tw-mb-1"
                id="delete"
                onClick={() => onViewed(params.data)}
              >
                <Tooltip title="Visualizar" placement="top">
                  <img src={ViewIcon} className="tw-mr-1 tw-w-5 tw-h-5" />
                </Tooltip>
              </div>
            </div>
          );
        }
      },
    },
  ]);

  const { t, i18n } = useTranslation();

  const onGridReady = async (params: any) => {
    let cols: any[] = [];
    columns.forEach(column => {
      // Realiza a tradução da key.
      if (column.headerName) {
        column.headerName = i18n.t(column.headerName);
      }

      cols.push(column);
    });

    console.log(crudButtons);

    crudButtons.forEach((buttons: any) => {
      cols.push(buttons);
    });

    console.log(cols);

    setColDefs(cols);
    setGridApi(params.api);

    const reqDTO = {
      filters: filters,
      orders: [],
      size: 1000,
      page: 0,
    };

    api.post('/delivery/tabelas/frete/pageable', reqDTO).then(response => {
      console.log(response.data.content);
      setRowData(response.data.content);
      // props.setRowData?.(response.data.content);
    });

    if (gridRef.current) {
      const api = gridRef.current.api;
      // api.setColumnFilterModel()
      await api.setColumnFilterModel('status', {
        filterType: 'text',
        type: 'equals',
        filter: 'ACTIVE',
      });
      api.onFilterChanged();
    }
  };

  function onUpdated(data: any) {
    console.log(data);
    if (data.status === 'ACTIVE' && data.publicada == false) {
      let rows = [];
      rows.push(data);
      setSelectedRow(rows);
      onUpdate(rows);
    } else {
      FrontendNotification('Operação não permitida!', NotificationType.WARNING);
    }
  }

  function onDelete(data: any) {
    if (data.status === 'ACTIVE') {
      let rows = [];
      rows.push(data);
      setSelectedRow(rows);
      onRemove(rows);
    } else {
      FrontendNotification('Operação não permitida!', NotificationType.WARNING);
    }
  }

  function onCreate() {
    onCreated();
  }

  function onPublished(data: any) {
    if (!data.publicada && data.status === 'ACTIVE') {
      let rows = [];
      rows.push(data);
      setSelectedRow(rows);
      onPublish(rows);
    } else {
      FrontendNotification(
        'Não é permitido publicar tabela de frete inativa!',
        NotificationType.WARNING
      );
    }
  }

  function onCopyed(data: any) {
    let rows = [];
    rows.push(data);
    setSelectedRow(rows);
    onCopy(rows);
  }

  function onViewed(data: any) {
    let rows = [];
    rows.push(data);
    onView(rows);
  }

  const onSelectionChanged = (params: SelectionChangedEvent) => {
    const selectedRows = params.api.getSelectedRows();
    setSelectedRow(selectedRows);
  };

  console.log(selectedRow);

  return (
    <Container>
      {/* <div className="tw-flex tw-items-center">
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onCreate}
            id="btnCreate"
          >
            <CreateIcon className="tw-mr-1" /> {t('action.create')}
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onUpdated}
            id="btnUpdate"
          >
            <UpdateIcon className="tw-mr-1" /> {t('action.update')}
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onDelete}
            id="btnDelete"
          >
            <RemoveIcon className="tw-mr-1" /> {t('action.remove')}
          </button>
        </div> */}
      {/* <div className="tw-flex tw-items-center tw-mt-2">
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onCopyed}
            id={'btnDelete'}
          >
            <img src={ContentCopyIcon} className="tw-mr-1" /> Copiar
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onPublished}
            id={'btnDelete'}
          >
            <img src={PublishIcon} className="tw-mr-1" /> Publicar
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onViewed}
            id={'btnDelete'}
          >
            <img src={ViewTemplateIcon} className="tw-mr-1" /> Visualizar
          </button>
        </div> */}
      <div className="ag-theme-quartz tw-h-80 tw-mb-6">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          rowSelection="single"
          rowModelType="clientSide"
          pagination={true}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
        />
        {/* <Grid<IShippingTable>
          columns={columns}
          pagination={true}
          perPage={100}
          rowSelection={RowSelection.SINGLE}
          path="/delivery/tabelas/frete"
          selectedRows={selectedRow}
          setSelectedRows={setSelectedRow}
          onUpdate={onUpdated}
          onDelete={onDelete}
          onCreate={onCreate}
          showCrudButtons={true}
          customButtons={[
            {
              label: 'Copiar',
              action: () => onCopyed(),
              icon: ContentCopyIcon,
            },
            {
              label: 'Publicar',
              action: () => onPublished(),
              icon: PublishIcon,
            },
            {
              label: 'Visualizar',
              action: () => onViewed(),
              icon: viewTemplateIcon,
            },
          ]}
          filters={filters}
        /> */}
      </div>
    </Container>
  );
};

export default ListShippingTable;
