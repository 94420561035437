import React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';

// import { Container } from './styles';

interface Props {
  title: string;
  onOpenFilters: () => void;
  children: JSX.Element;
}

const BoxSection: React.FC<Props> = (props: Props) => {
  return (
    <div
      className="tw-w-full tw-h-full tw-bg-[#fff] tw-rounded-md tw-shadow-md"
      style={{ borderTop: '7px solid #EA004C' }}
      id="reportsSection"
    >
      <div className="tw-w-full tw-p-3">
        <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
          <h1 className="tw-text-lg tw-text-[rgb(0, 48, 73)] tw-font-bold">
            {props.title}
          </h1>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-rounded-md tw-text-sm tw-text-[#FFF] tw-p-2"
            onClick={props.onOpenFilters}
          >
            <FilterListIcon
              sx={{ '&:hover': { color: 'white' } }}
              className="tw-mr-2"
            />
            Filtros
          </button>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default BoxSection;
