import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';
import TopBar from 'util/TopBar';
import './styles.scss';
// const jwt  = require('jsonwebtoken');

const ReportsMetabase = () => {
  const [findFilters, setFindFilters] = React.useState();
  const [metabaseURL, setMetabaseURL] = useState('');
  const userCompanyId = useSelector((state) => state.auth.user);
  const [subsidiaryId, setSubsidiaryId] = useState(sessionStorage.getItem('subsidiary'));

  const responseServiceFindByFilters = useCallback(async () => {
    try {
      const data = {
        filters: [{ field: 'status', value: 'ACTIVE', operation: 'EQUAL' }],
      };

      const response = await api.post('/cadastro/filiais/findByFilters', data);
      setFindFilters(response.data);



    } catch (error) {}
  }, []);

  const getReportsMetabase = useCallback(async (data) => {
    try {
      let METABASE_SITE_URL =
        'https://fastdelivery-metabase-develop.herokuapp.com';
      let METABASE_SECRET_KEY =
        '07204f86e8b9536137d1d9890217adf89b5c1db46341363a49df790cea9e6c71';
      var payload = {
        resource: { dashboard: 1 },
        params: {
          empresa: userCompanyId.company.id,
          filial: data !== null ? data : subsidiaryId
        },
        exp: Math.round(Date.now() / 1000) + 10 * 60,
      };
      // var token = jwt.sign(payload, METABASE_SECRET_KEY);
      var iframeUrl =
        METABASE_SITE_URL +
        '/embed/home/' +
        '#bordered=true&titled=true';

      setMetabaseURL(iframeUrl);
    } catch {}
  }, []);

  useEffect(() => {
    responseServiceFindByFilters();
  }, [responseServiceFindByFilters]);

  useEffect(() => {
    getReportsMetabase(null);
  }, [getReportsMetabase])
  return (
    <div>
      <TopBar
        listSubsidiary={findFilters}
        onChangeSubsidiary={(idSubsidiary) => {
          getReportsMetabase(idSubsidiary);
          setSubsidiaryId(idSubsidiary);
        }}
      />
      {metabaseURL.length > 0 && (
        <div className='containerMetabase'>
          <iframe
            src={metabaseURL}
            frameBorder="0"
            width="800"
            height="600"
            allowTransparency
          />
        </div>
      )}
    </div>
  );
};

export default ReportsMetabase;
