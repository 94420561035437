import { ApolloClient, InMemoryCache } from '@apollo/client';


const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  },
  cache: new InMemoryCache()
});

export default client;